<template>
  <div>
    <div v-if="mytitle != ''" style="height: 40px;text-align: center;line-height: 40px;"> {{mytitle}}</div>
    <div :id="'player_'+myvalue"
      style=" height: 200px;width: 100%;position: static; background-color: #faebd742;margin-bottom: 51px; "></div>
  </div>
</template>
<script> 
export default {
  props: {
    myvalue: {
      default: ''
    },
    mytitle: {
      default: ''
    },
    mydata: [],
  },
  data() {
    return {
      vodPlayerJs: '//player.polyv.net/script/player.js',
      vid: this.myvalue,
      isShow: false,
      mytype: {},
      timer: null,
      spall: 0,
    };
  },
  watch: {
    myvalue(val) {
      if (this.myvalue != '') {
        console.log("sp.....mounted22", this.myvalue);
        console.log("sp.....mounted22", this.mydata);
        this.loadPlayerScript(this.jn_load_sign);
      }
    },
  },
  mounted() {
    console.log("sp.....mounted", this.myvalue);
    //this.isshow = true;  
    if (this.myvalue != '') {
      console.log("sp.....mounted22", this.myvalue);
      this.loadPlayerScript(this.jn_load_sign);
    }
  },
  methods: {
    loadPlayerScript(callback) {
      console.log("sp.....loadPlayerScript");
      // if (!window.polyvPlayer) {
      //   const myScript = document.createElement('script');
      //   myScript.setAttribute('src', this.vodPlayerJs);
      //   myScript.onload = callback;
      //   document.body.appendChild(myScript);
      // } else {
      //   callback();
      // }
      callback();
    },
    loadPlayer(psign, pts) {
      this.isShow = true;
      const polyvPlayer = window.polyvPlayer;
      console.log("polyvPlayer", polyvPlayer);
      this.player = polyvPlayer({
        wrap: '#player_' + this.myvalue,
        width: '100%',
        height: '240px',
        //volume: 0.75,
        vid: this.myvalue,
        autoplay: 'true',
        loop: true,
        df: 3,
        cover_display: 'scaleAspectFill',
        ban_ui: 'off',
        skinLocation: 1,
        sign: psign,
        ts: pts
      });

      this.player.on('s2j_onPlayStart', (...params) => {
        console.log('s2j_onPlayStart', params);
      });

      this.player.on('s2j_onPlayOver', (...params) => {
        console.log('s2j_onPlayOver', params);
      });

      this.player.on('s2j_onPlayerError', (...params) => {
        console.log('s2j_onPlayerError', params);
      });

    },
    jn_load_sign() {
      var psign = this.mydata[0];
      var pts = this.mydata[1];
      this.loadPlayer(psign, pts);
    },
  },
  destroyed() {
    if (this.player) {
      this.player.destroy();
    }
    //clearInterval(this.timer);
  }
};
</script>