<template>
  <div>
    <van-nav-bar title="帮助说明" left-text="返回" left-arrow fixed placeholder @click-left="$router.push('/infohelp/'+mytype)">
    </van-nav-bar>
    <van-row :gutter="24" style="background: white;padding: 20px;">
      <van-col :span="24" style="text-align: center;">
        <div style="margin-top: 4px;margin-bottom: 4px;">{{data.title}}</div>
      </van-col>
      <van-col :span="24" style="text-align: center;">
        <cp_ship2 v-if="data_vid!=''" :myvalue="data_vid" :mydata="data_sign" />
      </van-col>
      <van-col :span="24" style="text-align: left;">
        <div v-html="data.content"></div>
      </van-col>
    </van-row>

  </div>
</template>
<script>
import { djarticleinfo } from '@/api/index'
import cp_ship2 from "@/components/cp_ship2";
export default {
  components: {
    cp_ship2,
  },
  data() {
    return {
      mytype: '',
      data: {},
      data_vid: '',
      data_sign: [],
    };
  },
  created() {
  },
  mounted() {
    this.load_api();
  },
  methods: {
    load_api() {
      console.log("$route.params.id", this.$route.params.id);
      console.log("getQueryVariable", this.getQueryVariable("type"));
      this.mytype = this.getQueryVariable("type");
      var sss = { guid: this.$route.params.id, type: '' };
      djarticleinfo(sss).then(response => {
        this.data = response.data.mydata;
        this.data_sign = response.data.mydata_sub;
        this.data_vid = this.data.file;
        console.log(this.data);
        console.log(this.data_sign);
      });
    },
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return (false);
    }
  },
};
</script>
<style>
</style>